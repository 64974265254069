.flex {
  display: flex;

  &__gap {
    gap: 1rem;
  }

  &__align {
    &--center {
      align-items: center;
    }

    &--start {
      align-items: flex-start;
    }

    &--end {
      align-items: flex-end;
    }
  }

  &__justify {
    &--center {
      justify-content: center;
    }

    &--start {
      justify-content: flex-start;
    }

    &--end {
      justify-content: flex-end;
    }
  }
}
