.sidebar {
  flex: 0 0 270px; /* Sidebar width */
  max-width: 270px; /* Sidebar maximum width */
  display: none; /* Initially hide sidebar */
  flex-direction: column;
  padding: 50px 20px;
  border-right: $light-background solid 1px;
  height: 100vh;

  @media (min-width: 768px) {
    display: flex; /* Hide sidebar on mobile */
  }

  &__section {
    &--title {
        position: relative;
        display: flex;
        padding: 6px 8px;
        font-size: 16px;
        line-height: 20px;
        min-height: 5px;
        margin-left: 8px;
        margin-right: 8px;
        border-radius: 6px;
        transition: background 33.333ms linear 0s;
        color: $orange;
        cursor: pointer;
        appearance: none;
        background: unset;
        border: unset;
        width: calc(100% - 16px);
        font-family: unset;
        text-align: unset;
        margin-top: unset;
        margin-bottom: unset;
        font-weight: 600;

    //   color: $orange;
    //   padding-left: 8px;
    //   font-size: 16px;
    }
    &--content {
      padding: 12px;
      color: $light-text;
      font-size: 1.2rem;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        color: $text;
      }
    }
  }

  &__item {
    padding-left: 12px;
    color: $light-text;
    font-size: 14px;
  }
}
