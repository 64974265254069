// --------------------------------------------------------------------------
// Width
// --------------------------------------------------------------------------
.w {
  width: 100%;

  &--50 {
    width: 50%;
  }

  &--25 {
    width: 25%;
  }
}

// --------------------------------------------------------------------------
// Margins
// --------------------------------------------------------------------------
.margin-top {
  margin-top: 32px;

  &__large {
    margin-top: 64px;
  }
}
