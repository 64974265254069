* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "SF Mono";
  list-style: none;
  scroll-behavior: smooth;
  scroll-margin: 100px;
}
body {
  background-color: $background;
}

.disable-scroll {
  overflow-y: hidden;
  height: 100vh;
}

section {
  margin-bottom: 250px;
}
.grid {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.column {
  flex: 1;
  width: 100%;
}

.c-right {
  display: flex;
  justify-content: flex-end;
}

.c-center {
  display: flex;
  justify-content: center;
}

.loader {
  img {
    width: 250px;
  }
}

@media (min-width: 889px) {
  .grid {
    display: flex;
    flex-direction: row;
  }
  .column {
    width: 50%;
  }
}


.margin-top {
  margin-top: 32px;
}

.w-100{
  width: 100%;
}